import React from "react"
import { Jumbotron } from "react-bootstrap"
import { PublicLayout } from "../components/layout"
import { SubHeading } from "../components/LocaleFields"
import { Seo } from "../components/navigation"


const NotFoundPage = () => (
  <PublicLayout>
    <Seo.NotFound />
    <Jumbotron className="bg-white px-0 py-0 py-md-4">
      <SubHeading className="flex-grow-1 mb-3">Page not found</SubHeading>
      <p>The page you requested could not be found.</p>
    </Jumbotron>
  </PublicLayout>
)

export default NotFoundPage
